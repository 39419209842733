//REACT
import React from "react";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
//STYLES
import "./AgendaColumn.css";

import AgendaCard from "../AgendaCard/AgendaCard";

const AgendaColumn = ({ column, display, number, date, holidays }) => {
  const displayMobile = useWindowSize();
  if (!displayMobile) {
    return (
      <div className="agenda-column__container">
        <div className="agenda-column__day__wrapper">
          {number !== undefined && (
            <p className="agenda-column__day__number">{number}</p>
          )}
        </div>
        <div className="agenda-column__wrapper">
          {display &&
            column !== undefined &&
            column.map((item, index) => {
              return (
                <AgendaCard
                  section={item.section}
                  subsection={item.subsection}
                  time={item.time}
                  book={item.book}
                  info={item.info}
                  type={item.type}
                  filter={item.filter}
                  filter2={item.filter2}
                  key={index}
                  route={item.route}
                  holidays={holidays}
                  bookAddressMail={item.bookAddressMail}
                  bookAddressRoute={item.bookAddressRoute}
                  externalBookAddress={item.externalBookAddress}
                  externalInfo={item.externalInfo}
                  sell={item.sell}
                />
              );
            })}
          {display && holidays !== undefined && (
            <AgendaCard holidays={holidays} />
          )}
        </div>
      </div>
    );
  } else if (displayMobile) {
    return null;
  }
};

export default AgendaColumn;
