import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";

import { Container, Title, Text } from "./CarouselStyles.js";
import "./HomePageCarousel.css";

const HomePageCarusel = ({ info, color, backgroundColor }) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    if (id === 0) {
      navigate("/markets");
    } else if (id === 1) {
      navigate("/schedule");
    } else if (id === 2) {
      navigate("/workshops");
    } else if (id === 3) {
      navigate("/gastronomy");
    }
  };
  return (
    <Container backgroundColor={backgroundColor}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        scrollbar={false}
        modules={[Pagination]}
      >
        {info !== undefined &&
          info.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div style={{ paddingTop: "30%" }}>
                  {item !== undefined && (
                    <Title color={color}>{item.title}</Title>
                  )}
                  {item !== undefined && (
                    <Text color={color}>
                      {item.paragraph1}
                      {item.paragraph2 !== undefined && item.paragraph2}
                    </Text>
                  )}
                </div>

                {item !== undefined && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "11%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Text
                      color={color}
                      onClick={() => {
                        handleClick(index);
                      }}
                    >
                      {item.footer.footer1}
                    </Text>

                    {item.footer.footer2 !== undefined && (
                      <Text
                        color={color}
                        onClick={() => {
                          navigate("/schedule");
                        }}
                      >
                        {item.footer.footer2}
                      </Text>
                    )}
                  </div>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </Container>
  );
};

export default HomePageCarusel;
