//REACT
import React, { useContext, useState } from "react";
import { UserActivity } from "../../../context/UserActivity";
import { useWindowSize } from "../../../custom-hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarActivity } from "../../../context/NavbarActivity";
//STYLES
import {
  NavbarContainer,
  NavbarText,
  HamburgerContainer,
  HamburgerRow,
} from "./NavbarStyles";
import "./Navbar.css";
import { TfiClose } from "react-icons/tfi";
import { useEffect } from "react";

const Navbar = () => {
  const {
    dropdownMenuSelected,
    setDropdownMenuSelected,
    displayCookiesBanner,
  } = useContext(UserActivity);

  const navigation = useNavigate();
  const {
    navbarColor,
    hamburgerColor,
    setHamburgerColor,
    setNavbarColor,
    navbarBackground,
    setNavbarBackground,
    navbarOpacity,
  } = useContext(NavbarActivity);
  const [scrollPercent, setScrollPercent] = useState(0);
  const displayMobile = useWindowSize();
  useEffect(() => {}, []);
  let b = useLocation().pathname;
  useEffect(() => {
    switch (b) {
      case "/":
        setNavbarBackground("transparent");
        console.log("hamburgerColor in Navbar: ", hamburgerColor);
        if (dropdownMenuSelected) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");
        }

        break;
      case "/spaces/home":
        setNavbarBackground("transparent");
        if (dropdownMenuSelected) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
        }

        break;
      case "/spaces/rochebobois":
        setNavbarBackground("transparent");
        if (dropdownMenuSelected) {
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--mercablue)");
        }

        break;
      case "/markets":
        setNavbarBackground("transparent");
        if (dropdownMenuSelected) {
          setNavbarColor("var(--mercared)");
          setHamburgerColor("var(--mercared)");
        }

        break;
      case "/workshops":
        setNavbarBackground("transparent");
        if (dropdownMenuSelected) {
          setNavbarColor("mercagreen");
          setHamburgerColor("lightgreen");
        }
        break;
      case "/gastronomy":
        setNavbarBackground("transparent");
        if (dropdownMenuSelected) {
          setNavbarColor("var(--mercapink)");
          setHamburgerColor("var(--mercapink)");
        }
        break;
      case "/contact":
        if (!displayMobile) {
          setNavbarColor("var(--mercapink)");
          setHamburgerColor("var(--mercared)");
          setNavbarBackground("transparent");
        } else if (displayMobile) {
          setNavbarColor("var(--mercapink)");
          setHamburgerColor("var(--mercapink)");
          setNavbarBackground("transparent");
        }
        break;
      case "/schedule":
        if (dropdownMenuSelected) {
          setNavbarColor("var(--mercagreen)");
          setHamburgerColor("var(--skyblue)");
          setNavbarBackground("transparent");
        } else if (dropdownMenuSelected === false) {
          setNavbarBackground("var(--mercagreen)");
          setNavbarColor("var(--skyblue)");
          setHamburgerColor("var(--skyblue)");
        }
        break;
      case "/event":
        setNavbarBackground("transparent");
        setNavbarColor("var(--mercagreen)");
        setHamburgerColor("var(--mercagreen)");
        break;
      case "/company":
        setNavbarBackground("transparent");
        setNavbarColor("var(--mercagreen)");
        setHamburgerColor("var(--mercagreen)");
        break;
      default:
        break;
    }
  }, [b, scrollPercent, dropdownMenuSelected]);

  const handleScroll = () => {
    let scrollTop = window.scrollY;
    let winHeight = window.innerHeight;
    setScrollPercent(Math.round((scrollTop / winHeight) * 100));
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <NavbarContainer
      id="navbar"
      background={navbarBackground}
      opacity={navbarOpacity}
      margin={displayCookiesBanner ? "60px" : "auto"}
    >
      <NavbarText
        color={navbarColor}
        onClick={() => {
          setDropdownMenuSelected(false);
          navigation("/");
        }}
      >
        mercantic
      </NavbarText>
      {!dropdownMenuSelected && (
        <HamburgerContainer
          onClick={() => {
            setDropdownMenuSelected(!dropdownMenuSelected);
            setNavbarBackground("transparent");
          }}
        >
          <HamburgerRow backgroundColor={hamburgerColor}></HamburgerRow>
          <HamburgerRow backgroundColor={hamburgerColor}></HamburgerRow>
          <HamburgerRow backgroundColor={hamburgerColor}></HamburgerRow>
        </HamburgerContainer>
      )}
      {dropdownMenuSelected && (
        <TfiClose
          onClick={() => {
            setDropdownMenuSelected(false);
          }}
          style={{ color: `${hamburgerColor}` }}
          size={displayMobile ? 20 : 30}
          className="close-button-navbar"
        />
      )}
    </NavbarContainer>
  );
};

export default Navbar;
