import React from "react";

//STYLES
import "./GastronomiaDefaultPage.css";

const GastronomiaDefaultPage = ({ title, text, schedule, id }) => {
  if (id !== undefined) {
    return (
      <div className="gastronomiaPage-frame2" id={id.idName}>
        <div className="gastronomiaPage-frame2-top-wrapper">
          <h3>
            {title.br1}
            <br />
            {title.br2 && title.br2}
          </h3>
          <p>{text}</p>
        </div>
        <div className="gastronomiaPage-frame2-bottom-wrapper">
          <p>
            {schedule.br3 !== undefined &&
              schedule.br4 !== undefined &&
              schedule.br5 !== undefined &&
              schedule.br6 !== undefined &&
              `${schedule.br1} | ${schedule.br2} | ${schedule.br3} | ${schedule.br4} | ${schedule.br5} | ${schedule.br6}
              `}
            {schedule.br3 !== undefined &&
              schedule.br4 !== undefined &&
              schedule.br5 !== undefined &&
              schedule.br6 === undefined &&
              `${schedule.br1} | ${schedule.br2} | ${schedule.br3} | ${schedule.br4} | ${schedule.br5} 
              `}
            {schedule.br3 !== undefined &&
              schedule.br4 !== undefined &&
              schedule.br5 === undefined &&
              schedule.br6 === undefined &&
              `${schedule.br1} | ${schedule.br2} | ${schedule.br3} | ${schedule.br4}
              `}
            {schedule.br3 !== undefined &&
              schedule.br4 === undefined &&
              schedule.br5 === undefined &&
              schedule.br6 === undefined &&
              `${schedule.br1} | ${schedule.br2} | ${schedule.br3} 
              `}
            {schedule.br3 === undefined &&
              schedule.br4 === undefined &&
              schedule.br5 === undefined &&
              schedule.br6 === undefined &&
              `${schedule.br1} | ${schedule.br2} 
              `}
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GastronomiaDefaultPage;
