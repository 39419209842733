import React, { createContext, useState, useEffect } from "react";
import { homePageData } from "../data/HomePageData";
import { newsletterData } from "../data/NewsletterData";
import { footerData } from "../data/FooterData";
import { dropdownData } from "../data/DropdownData";
import { gastronomiaData } from "../data/GastronomiaPageData";
import { tallerData } from "../data/TallerData";
import { mercatsData } from "../data/MercatsData";
import { espaisData } from "../data/EspaisData";
import { ContacteData } from "../data/ContacteData";
import { esdevenimentData } from "../data/EsdevenimentData";
import { empresaData } from "../data/EmpresaData";
import { cookiesData } from "../data/Cookies";
export const LanguageActivity = createContext();

export const LanguageActivityProvider = ({ children }) => {
  const [languageSelected, setLanguageSelected] = useState({ catala: true });
  const [data, setData] = useState({});
  const [serverData, setServerData] = useState(null);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://mercantic-server-5dade5a62aed.herokuapp.com/api/data"
        );

        if (response.ok) {
          const serverData = await response.json();
          setServerData(serverData[0].data);
          setDataIsLoaded(true);
        } else {
          console.error("Error fetching data from the API");
        }
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (dataIsLoaded) {
      if (languageSelected) {
        if (languageSelected.catala) {
          setData({
            homePage: homePageData.catala,
            newsletter: newsletterData.catala,
            footer: footerData.catala,
            dropdown: dropdownData.catala,
            gastronomia: gastronomiaData.catala,
            tallers: tallerData.catala,
            mercats: mercatsData.catala,
            espais: espaisData.catala,
            agenda: serverData.catala,
            contacte: ContacteData.catala,
            esdeveniment: esdevenimentData.catala,
            empresa: empresaData.catala,
            cookies: cookiesData.catala,
          });
        } else if (languageSelected.castella === true) {
          setData({
            homePage: homePageData.castella,
            newsletter: newsletterData.castella,
            footer: footerData.castella,
            dropdown: dropdownData.castella,
            gastronomia: gastronomiaData.castella,
            tallers: tallerData.castella,
            mercats: mercatsData.castella,
            espais: espaisData.castella,
            agenda: serverData.castella,
            contacte: ContacteData.castella,
            esdeveniment: esdevenimentData.castella,
            empresa: empresaData.castella,
            cookies: cookiesData.castella,
          });
        } else if (languageSelected.angles === true) {
          setData({
            homePage: homePageData.angles,
            newsletter: newsletterData.angles,
            footer: footerData.angles,
            dropdown: dropdownData.angles,
            gastronomia: gastronomiaData.angles,
            tallers: tallerData.angles,
            mercats: mercatsData.angles,
            espais: espaisData.angles,
            agenda: serverData.angles,
            contacte: ContacteData.angles,
            esdeveniment: esdevenimentData.angles,
            empresa: empresaData.angles,
            cookies: cookiesData.angles,
          });
        }
      }
    }
  }, [languageSelected, dataIsLoaded]);
  useEffect(() => {
    if (localStorage.getItem("language")) {
      setLanguageSelected(JSON.parse(localStorage.getItem("language")));
    } else {
      setLanguageSelected({
        catala: true,
        castella: false,
        angles: false,
      });
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://mercantic-server-5dade5a62aed.herokuapp.com/"
        );

        if (response.ok) {
          const serverData = await response.json();
          setServerData(serverData);
          console.log("serverData retrieved!");
        } else {
          console.error("Error fetching data from the API");
        }
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    };
    fetchData();
    //eslint-disable-next-line
  }, []);
  return (
    <LanguageActivity.Provider
      value={{
        languageSelected,
        setLanguageSelected,
        data,
        setData,
      }}
    >
      {children}
    </LanguageActivity.Provider>
  );
};
